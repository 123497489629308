/* Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/* Color Pallet */
:root {
  --PrimaryGreen: #25E021;
  --PrimaryRed: #F00;
  --PrimaryOrange: #E17B00;
  --inter: 'Inter', sans-serif;
}

/* buttons */
.btn-primary {
  background-color: var(--PrimaryRed) !important;
}

h1 h2 h3 p html body {
  font-family: var(--inter) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-hero {
  background-image: url('/src/assets/Hero_image.png');
}

.bg-section-vision {
  background-image: url('/src/assets/visibg_image.png');
}

.bg-red-custom {
  background: #F55;
}

.bg-contact {
  background-image: url('/src/assets/contactus_bg.png');
}

.cnl-red {
  color: var(--PrimaryRed);
}

.active {
  background-color: var(--PrimaryRed) !important;
  color: white !important;
}