@tailwind base;
@tailwind components;
@tailwind utilities;
/* Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/* Color Pallet */
:root {
  --PrimaryGreen: #25E021;
  --PrimaryRed: #F00;
  --PrimaryOrange: #E17B00;
  --inter: 'Inter', sans-serif;
}

h1 h2 h3 p html body {
  font-family: var(--inter) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}